@font-face {
  font-family: 'BrandonGrotesque';
  src: url('./fonts/Brandon Grotesque/Brandon_reg.otf');
}

html {
  background-color: #1f1f1f;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'BrandonGrotesque';
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.outer {
  margin: auto;
  max-width: 840px;
  border-radius: 5px;
  background: rgb(116, 136, 156);
  padding: 20px;
}

.accordion {
  margin: auto;
}

.navbar-brand {
  padding: 20px;
  font-size: 30px;
  font-family: 'BrandonGrotesque';
}

.card-header {
  color: rgb(214, 198, 187);
  font-size: 22px;
  font-family: 'BrandonGrotesque';
}

.card-body {
  background: #28282e;
  color: #ececee;
    font-size: 18px;
  font-family: 'BrandonGrotesque';
}

li {
  margin: 0.5em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
